/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap'); */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/src/fonts/roboto/roboto_mono.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: Duospace; /* "iA Writer Duospace"; */
    font-weight: normal;
    font-style: normal;
    src: url("fonts/iA/iAWriterDuospace-Regular.woff2") format("woff2");
}
@font-face {
    font-family: Duospace;
    font-weight: normal;
    font-style: italic;
    src: url("fonts/iA/iAWriterDuospace-Italic.woff2") format("woff2");
}
@font-face {
    font-family: Duospace;
    font-weight: bold;
    font-style: normal;
    src: url("fonts/iA/iAWriterDuospace-Bold.woff2") format("woff2");
}
@font-face {
    font-family: Duospace;
    font-weight: bold;
    font-style: italic;
    src: url("fonts/iA/iAWriterDuospace-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: Jetbrains Mono;
    font-style: normal;
    src: url("fonts/jetbrains/mono.ttf") format("ttf");
}
@font-face {
    font-family: Jetbrains Mono;
    font-style: italic;
    src: url("fonts/jetbrains/mono-italic.ttf") format("ttf");
}

:root {
    --light: rgb(244, 241, 232);
    --light-d: rgb(232, 228, 218);
    --dark: rgb(12, 12, 12);
    --dark-l: rgb(16, 16, 16);
    --bg: rgb(19, 17, 37);

    /* --background: linear-gradient(15deg,#609e98,#e2d291); */
    /* --background: linear-gradient(#fff4 0 0), linear-gradient(15deg,#609e98ee,#e2d291) fixed, linear-gradient(#fff 0 0); */
    --background: white;
    --icon: darkseagreen;
    --background: #f6f3ed;
    --background: #eeebe6;
    /* rgb(255, 145, 145) 0%, rgb(255, 227, 114)  rgb(255, 180, 30) */
    /* --background: linear-gradient(15deg, rgb(255, 145, 145) 0%, rgb(255, 227, 114) 100%); */
    /* --background: linear-gradient(15deg, rgb(255 151 151 / 63%) 0%, rgb(255 207 114 / 67%) 100%) fixed, linear-gradient(#fff 0 0); */
    /* --background: linear-gradient(15deg, rgb(255 151 151 / 63%) 0%, rgb(255 207 114 / 79%) 100%) fixed, linear-gradient(#fff 0 0); */
    /* --background: #f8f8f8; */
    --bottom-margin: .3rem;
    --bottom-margin: .9rem;
    --bottom-margin: .4rem;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    /* font-size: max(8px, min(3.6vh, min(3.6vw, 22px))) !important; */
    /* font-size: max(8px, min(3.6vw, 22px)); */
    font-size: min(3.6vw, 17px);
    /* font-size: max(16px, min(min(2vh, 2vw), 22px)) !important; */
    /* font-size: max(12px, min(3.6vh, min(3.6vw, 22px))) !important; */
    /* font-size: max(16px, min(3.6vw, 22px)) !important; */
    /* font-size: min(4.2vw, 20px) !important; */
    /* font-size: min(4vw, 20px) !important; */
    overflow: hidden;
    /* font-family: Duospace, Courier, monospace !important; */
    font-family: SFMono-Regular, Duospace, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", noto-emoji, monospace !important;
    font-family: SFMono-Regular, Duospace, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
body {
    line-height: 1.5;
}
* {
    /* font-family: Duospace, Courier, monospace; */
    box-sizing: border-box;
    font-family: inherit;
    /* position: relative; */
    margin-top: 0;
    /* vertical-align: middle; */
}
a {
    text-decoration: underline;
    /* color: #378dff; */
    /* color: #72afff; */
    color: #388eff;
    color: #000;
    cursor: pointer;
}
a:hover { text-decoration: none }
input { margin: 0; line-height: inherit }
input[type=text], input[type=number], textarea {
    cursor: text;
}
button {
    cursor: pointer;
    font-size: 1em;
}
sup { vertical-align: top; font-size: 0.6em; }
code { display: inline-block }

/* label, button, .button, .action { line-height: 1.5 }
div, p, span, a { line-height: 1.2 }
span * { min-height: 1em }

p { margin-bottom: 1em }
u { text-decoration: underline } */
table { border-collapse: collapse }

body input, body textarea {
    font-size: max(16px, 1em);
}

html > body {
    /* background: linear-gradient(15deg, rgb(255, 145, 145) 0%, rgb(255, 227, 114) 100%) fixed; */
    /* background: linear-gradient(15deg,#609e98,#e2d291) fixed; */
    /* background: black; */
    /* background: #131125; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-family: 'Roboto Mono', Courier, monospace !important; */
    /* font-family: Duospace, Courier, monospace !important; */
    /* text-shadow: 1px 2px 4px #00000020; */

    /* background: transparent; */

    background: black;
    color: white;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    /* overflow-y: auto; */
}

.clickable {
    cursor: pointer;
    pointer-events: all;
    user-select: none;
}
.column, .row {
    display: flex;
    align-items: flex-start;
}
.column {
    flex-direction: column;
}
.row {
    flex-direction: row;
}
.centering, .center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.center-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.center-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.shadow {
    text-shadow: 1px 2px 4px #00000020;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: .5rem;
    background: transparent;
}
::-webkit-scrollbar-track {
    /* border-radius: .25rem; */
    /* background: #fff2; */
    /* background: linear-gradient(to right, #0000 50%, #fff4 50%); */
    /* background: linear-gradient(to right, #0000 50%, #0000000b 50%); */
    /* background-color: inherit;
    filter: invert(100%); */
    /* padding-left: .2rem; */
    margin-right: .25rem;
    background: transparent;
}
::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active {
    /* background: linear-gradient(to right, #0000 50%, #0000000b 50%); */
    background: #0000000b;
}
::-webkit-scrollbar-thumb {
    /* border-radius: calc(.25rem + .5rem); */
    /* background: #ffffffcd; */
    /* width: .5rem; */
    /* background: #fffc; */
    background-color: #0002;
    /* border-left: .5rem solid transparent; */
    background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active {
    /* background: #8888; */
    background-color: #0005;
}
.invert-scrollbar:is(::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active) {
    background: #ffffff0b;
}
.invert-scrollbar::-webkit-scrollbar-thumb {
    background-color: #fff2;
}
.invert-scrollbar:is(::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active) {
    background-color: #fff5;
}

iframe {
    border: 0;
}

/* fixed background doesn't work for safari - this hacky selector matches safari */
@media not all and (min-resolution:.001dpcm){
    @supports (-webkit-appearance:none) {
        /*
        fixed is fine for elements which fit in the initial viewport
        select those manually for now
        */
        *:not(#main *), *:not(#main *)::before, *:not(#main *)::after {
            background-attachment: scroll !important;
        }
    }
}
/* *:not(:root:root), *:not(:root:root)::before, *:not(:root:root)::after {
} */

/* ::-webkit-scrollbar-track {
    margin-top: 0;
    padding-top: 0;
    padding-left: .2rem;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    -webkit-border-radius: 7px;
    *//* background-color: rgba(0, 0, 0, 0.15); */
    /* background-color: transparent; */
    /* -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); */
    /* cursor: pointer; *//*
}
::-webkit-scrollbar:hover {
    background-color: #0000000b;
}
::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    *//* background-color: rgba(255, 255, 255, 0.8); *//*
    background-color: #0003;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb:hover {
    background-color: #0005;
}
::-webkit-scrollbar-corner {
    display: none;
} */

/* * {
    scrollbar-width: thin;
    scrollbar-color: #0005 #0000000b;
} */